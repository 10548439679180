<template>
  <div>
    <Pane />

    <div class="container">
      <div class="sider">
        <Menu @changeCategory="changeCategory" />
      </div>
      <div class="body">
        <div class="content">
          <div class="card-header">
            <div class="card-title">
              <a-icon type="profile" class="icon" />
              <span>{{ category.name }}</span>
            </div>

            <!-- <PermissionButton path="add">
              <a-button type="primary" @click="add">创建</a-button>
            </PermissionButton>-->
          </div>

          <div v-if="list.length > 0">
            <div class="list">
              <div v-for="item in list" :key="item.id" @click="onClick(item)">
                <div class="doc">
                  <img
                    class="news-image"
                    :src="$imgCrop(item.face, 400, 240)"
                    alt="图片"
                    v-if="item.face"
                  />
                  <div v-else class="news-image">{{category.name}}</div>
                  <div class="news-title" :title="item.title">{{ item.title }}</div>
                  <div class="between" style="padding: 8px">
                    <div class="time">{{ item.createAt }}</div>

                    <!-- <a-space>
                      <PermissionButton path="edit">
                        <a href="#" @click.prevent.stop="editDoc(item)">编辑</a>
                      </PermissionButton>
                      <PermissionButton path="delete">
                        <a
                          href="#"
                          class="danger"
                          @click.prevent.stop="deleteDoc(item)"
                          >删除</a
                        >
                      </PermissionButton>
                    </a-space>-->
                  </div>
                </div>
              </div>
            </div>

            <div class="center" style="margin: 24px 0 12px">
              <a-pagination
                show-quick-jumper
                :current="current"
                :pageSize="pageSize"
                :total="total"
                :showTotal="(total) => `共 ${total} 条记录`"
                @change="onChange"
              />
            </div>
          </div>

          <div v-else>
            <a-empty />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import Menu from "./components/menu";
import { fetchList, remove } from "@/api/document";

export default {
  mixins: [watermark],

  components: {
    Menu
  },

  data() {
    return {
      category: {},
      list: [],
      total: 0,

      current: 1,
      pageSize: 8
    };
  },

  mounted() {
    // 设置水印
    const body = document.querySelector(".body");
    if (body) {
      this.setWatermark(body);
    }
  },

  methods: {
    changeCategory(category) {
      this.category = category;
      this.current = 1;
      this.pageSize = 8;
      this.getList();
    },
    getList() {
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        category: this.category.value
      }).then(res => {
        if (Array.isArray(res.list)) {
          this.list = res.list;
          this.total = res.totalSize;
        }
      });
    },

    onChange(current) {
      this.current = current;
      this.getList();
    },

    add() {
      this.$router.push("/document/add");
    },
    onClick(item) {
      console.log("item", item);
      this.$router.push("/document/detail?id=" + item.id);
    },

    editDoc(doc) {
      this.$router.push(`/document/edit?id=${doc.id}`);
    },
    deleteDoc(doc) {
      const that = this;
      this.$confirm({
        title: "确认要删除这篇文档吗？",
        onOk() {
          remove({
            id: doc.id
          }).then(() => {
            that.getList();
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: 140px 5fr;
  gap: 8px;
  .sider {
    background-color: #fff;
  }
  .body {
    background-color: #fff;
  }
}

.content {
  padding: 8px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.card-title {
  font-size: 16px;
  .icon {
    margin-right: 8px;
    font-size: 16px;
    color: #1890ff;
  }
}

.doc {
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #f0f0f0;
}

.news-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  object-fit: cover;
  font-size: 20px;
  background: #1890ff;
  color: #fff;
}

.news-title {
  max-width: 300px;
  padding: 12px 8px 0;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.time {
  color: #999;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
</style>