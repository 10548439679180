<template>
  <div class="sider">
    <div v-for="item in typeList" :key="item.value">
      <div class="menu-title left">
        <img src="@/assets/icon2.png" alt="图标" />
        <span>{{ item.name }}</span>
      </div>
      <div class="menu">
        <div
          class="menu-item"
          v-for="element in item.children"
          :key="element.value"
          @click="changeActive(element)"
          :class="activeCategory.value === element.value ? 'active' : ''"
        >
          {{ element.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeCategory: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("documentType");
    },
  },

  mounted() {
    if (this.typeList.length > 0) {
      if (
        Array.isArray(this.typeList[0].children) &&
        this.typeList[0].children.length > 0
      ) {
        this.activeCategory = this.typeList[0].children[0];
      }
    }
  },

  watch: {
    activeCategory(newValue) {
      this.$emit("changeCategory", newValue);
    },
  },

  methods: {
    changeActive(value) {
      this.activeCategory = value;
      // this.active = value;
    },

    onClick(item) {
      console.log("item", item);
      this.$router.push("/document/add");
    },
  },
};
</script>

<style lang="less" scoped>
.sider {
  padding: 8px;
  background-color: #fff;
}
.menu-title {
  font-size: 16px;
  img {
    height: 16px;
    margin-right: 8px;
  }
}
.menu {
  padding: 0 24px 16px;
  .menu-item {
    margin-top: 8px;
    color: #666;
    cursor: pointer;
  }
  .active {
    color: #1890ff;
    font-weight: bold;
  }
}
</style>